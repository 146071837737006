@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.plus-jakarta-sans-unique {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid #c9184a !important;
}

img {
  max-width: 100%;
}

.textHidden {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Heartbeat button with color transition */
.heartbeat-button {
  transition: background-color 0.5s ease, transform 0.5s ease;
}

/* Color and heartbeat for playing state */
.playing {
  background-color: #c9184a; /* Red when playing */
  animation: heartbeat 2s ease-in-out infinite; /* Heartbeat animation */
}

.innerhtml p {
  margin: 0;
}

/* Heartbeat animation keyframes */
@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.15);
  }
}

.CardEffect {
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.CardEffect:hover {
  transform: scale(1.1);
  border-color: #c9184a;
}

.site-layout-background {
  max-height: 100vh;
  overflow: auto;
}

.DeleteButton :hover {
  color: #c9184a;
}

.commentCard{
  .ant-card-body{
    padding: 0px !important;
  }
}

.commentCard{
  padding: 13px 10px 0px 15px;
  border: 1 px solid;
  border-radius: 20px;
  width: fit-content;
  margin-bottom: 14px;
}

.commentCard:hover{
  border-color: #c9184a;
}