.ant-space {
  display: flex;
  .ant-form-item {
    margin: 0;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.rbc-row-segment{
  position:relative;
}

.calendarClass{
  .rbc-off-range-bg{
    background: rgba(110,110,110,0.75);
  }
}
.rbc-today{
  background: rgba(241,36,94,0.65) !important;
}